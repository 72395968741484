/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, useEffect, useState } from 'react';
interface ImagePreviewContainerInterface {
  selectedMediaDetails: {
    elementType: string;
    fileName: string;
  };
  handleConfirmState: Function;
  setDecorativeText: Function;
  imageType: string;
  setSelectedMediaDetails: Function;
  fileNameDetails: { type?: string; isError?: boolean };
  setFileNameDetails: Function;
}
const ImagePreviewRightContainer = (props: ImagePreviewContainerInterface) => {
  const {
    selectedMediaDetails,
    handleConfirmState,
    setDecorativeText,
    imageType,
    setSelectedMediaDetails,
    fileNameDetails,
    setFileNameDetails,
  } = props;
  const [fileName, setFileName] = useState(selectedMediaDetails?.fileName);
  const { type, isError } = fileNameDetails;
  const [decorativeStatus, setDecorativeStatus] = useState(false);
  const [altText, setAltText] = useState('');

  useEffect(() => {
    const filename = fileName?.trim();
    const alterantiveText = altText.trim();
    const confirmButtonState =
      (filename !== '' && !decorativeStatus && alterantiveText === '') ||
      (decorativeStatus && filename === '') ||
      (filename === '' && !decorativeStatus && alterantiveText === '') ||
      (alterantiveText !== '' && !decorativeStatus && filename === '');
    const externalLinkButtonState = !decorativeStatus && alterantiveText === '';
    handleConfirmState &&
      handleConfirmState(
        selectedMediaDetails?.elementType === 'externalLink'
          ? externalLinkButtonState
          : confirmButtonState,
      );
  }, [altText, decorativeStatus, fileName, handleConfirmState, selectedMediaDetails?.elementType]);
  const handleFileName = (event: ChangeEvent<HTMLInputElement> | React.KeyboardEvent) => {
    if (type === 'alreadyExist') {
      setFileNameDetails({ type: '', isError: false });
    }
    event.stopPropagation();
    const value = (event.target as HTMLInputElement).value;
    const elementDetails = { ...selectedMediaDetails };
    elementDetails.fileName = value;
    setSelectedMediaDetails(elementDetails);
    setFileName(value);
  };
  const handleAltTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    if (type === 'fileNameMatching') {
      setFileNameDetails({ type: '', isError: false });
    }
    setAltText(value);
    setDecorativeText(value);
  };
  const handleDecorativeButtonEvent = () => {
    setDecorativeText(!decorativeStatus ? '' : altText);
    setDecorativeStatus(!decorativeStatus);
  };
  return (
    <>
      {selectedMediaDetails?.elementType !== 'externalLink' && (
        <div className='d-flex flex-column mb-2'>
          <label id='lint-image-file-name' htmlFor='lint-image-title-input' className='pb-1 mb-0'>
            File name *
          </label>
          <input
            id='lint-image-title-input'
            className='media-modal-file-name p-1'
            type='text'
            value={fileName?.replace(/ +/g, ' ')}
            autoComplete='off'
            disabled={imageType !== 'localImage'}
            onKeyDown={(event) => handleFileName(event)}
            onChange={(event) => handleFileName(event)}
          />
          <label
            id='lint-image-title-alert'
            htmlFor='lint-image-title-input'
            className={`pb-1 mb-0 text-danger ${
              type === 'alreadyExist' && isError ? '' : 'd-none'
            }`}
          >
            Oops! This file name already exists. Update the name to continue.
          </label>
        </div>
      )}
      <div className='d-flex flex-column'>
        <label
          className='pb-1 mb-0'
          htmlFor='lint-image-alt-input'
          id='lint-media-modal-label-for-alt-text'
        >
          Alternative text *
        </label>
        <textarea
          id='lint-image-alt-input'
          className='p-1 lint-image-modal-text-area'
          placeholder='Alternative text'
          maxLength={150}
          value={altText.replace(/ +/g, ' ')}
          disabled={decorativeStatus}
          onChange={(event) => {
            handleAltTextChange(event);
          }}
        />
        <label
          id='lint-media-details-modal-title-alt-alert'
          htmlFor='lint-image-alt-input'
          className={`pb-1 mb-0 text-danger  ${
            type === 'fileNameMatching' && isError ? '' : 'd-none'
          }`}
        >
          File name and alternate text should not be same.
        </label>
      </div>
      <div className='pt-2'>
        <label
          className='pb-1 mb-0 h5 cursor-pointer d-inline-flex align-items-center'
          htmlFor='lint-image-decorative-option-input'
        >
          <input
            onClick={(e) => e.stopPropagation()}
            id='lint-image-decorative-option-input'
            name='lint-image-decorative-option-input'
            className='p-1 me-2 cursor-pointer'
            type='checkbox'
            checked={decorativeStatus}
            onChange={() => handleDecorativeButtonEvent()}
          />
          <span style={{ fontSize: '15px' }}>Decorative image</span>
        </label>
      </div>
    </>
  );
};
export default ImagePreviewRightContainer;
