import { Node as ProseMirrorNode } from 'prosemirror-model';
import { NodeSelection, Selection as PMSelection } from 'prosemirror-state';

export interface NodeFindResult {
  node: ProseMirrorNode | null;
  start: number;
  end: number;
  before: number;
  after: number;
  depth: number;
}

export function findNodeTypeFromSelection(
  typeOrName: string | null,
  selection: PMSelection,
): NodeFindResult {
  const $from = selection.$from;

  if (typeOrName !== null) {
    if (selection instanceof NodeSelection) {
      const n = selection.node;

      if (n.type.name === typeOrName) {
        return {
          node: n,
          start: selection.from + 1,
          end: selection.to - 1,
          before: selection.from,
          after: selection.to,
          depth: selection.$from.depth + 1,
        };
      }
    }

    for (let i = $from.depth; i > 0; i--) {
      const n = $from.node(i);
      if (n.type.name === typeOrName) {
        return {
          node: n,
          start: $from.start(i),
          end: $from.end(i),
          before: $from.before(i),
          after: $from.after(i),
          depth: i,
        };
      }
    }
  }
  return { node: null, start: 0, end: 0, before: 0, after: 0, depth: 0 };
}
