/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Tooltip } from '@crystaldelta/loree-ui-components';
import { AlignmentButton } from './editorExtensions/alignmentButton';
import {
  createSelectionState,
  splitValueAndUnit,
  getNodeAttributes,
  getFontName,
  getFontSize,
  getTextAlign,
  getLineHeight,
  getBackgroundColor,
  getTextColor,
  setTextColor,
  setBackgroundColor,
  getCurrentLink,
  isLinkText,
  setBorderSize,
  setBorderStyle,
  setBorderColor,
  getBorderColor,
  getBorderSize,
  getBorderStyle,
  applyLink,
  isBulletList,
  isOrderedList,
  formatFontSize,
  applyStylesToList,
  isWholeContentSelected,
  setEditorConfig,
  setDefaultFontStyle,
  parseFontSize,
  getHeadingLevel,
} from './editorExtensions/utilityFunctions';
import { ReactComponent as BlockquoteButton } from './editorFormattingIcons/blockquoteButton.svg';
import { ReactComponent as LinkButton } from './editorFormattingIcons/linkButton.svg';
import { ReactComponent as ClearEditorButton } from './editorFormattingIcons/clearFormatButton.svg';
import { BackgroundColorButton } from './editorMenu/backgroundColorButton';
import { BorderColorButton } from './editorMenu/borderColorButton';
import { TextColorButton } from './editorMenu/foregroundColorButton';
import { CONSTANTS } from './editorUtilityFunctions/constants';
import { FontSectionContent } from './editorUtilityFunctions/fontstSection';
import { HeadingFormatButton } from './editorUtilityFunctions/formatButtonsHeading';
import { HistoryFormatButtons } from './editorUtilityFunctions/formatButtonHistory';
import { TextFormatButton } from './editorUtilityFunctions/formatButtonsTextFormat';
import { LineHeightButton } from './editorUtilityFunctions/lineHeightButton';
import { FontSizeData, LintEditorFormatPropsType } from './editorUtilityFunctions/lintEditorType';
import { ListElementOption } from './editorUtilityFunctions/listElementOptions';
import lintStyle from './editorUtilityFunctions/loreeInteractiveEditor.module.scss';
import MediaContent from './editorUtilityFunctions/mediaContent';
import { EditorFormatButton, FormatSection } from './editorUtilityFunctions/styledContents';
import { TableFormatContent } from './editorUtilityFunctions/tableButtonElement';
import { TextIndentButton } from './editorUtilityFunctions/textIndentButton';
import { WebaddressModal } from './editorUtilityFunctions/webAddress';
import { WordOptionsButton } from './editorUtilityFunctions/wordOptionButton';
import { findNodeTypeFromSelection } from './editorExtensions/findNodes';

export function LoreeInteractiveEditorFormatOptions(props: LintEditorFormatPropsType) {
  const { editor, editorConfig } = props;
  if (!editor) {
    return null;
  }

  if (editorConfig) {
    setEditorConfig(editorConfig);
  }

  const onChangeLineHeight = (value: string) => {
    editor.commands.setLineHeight(value);
  };
  const [selectionTypeName, setSelectionTypeName] = useState('');
  const [fontFamilyName, setFontFamilyName] = useState(getFontName(editor).trim() ?? 'Source sans pro');
  const [fontSizeValue, setFontSizeValue] = useState({
    size: 16,
    unit: 'px',
  });
  const [textAlign, setTextAlign] = useState('');
  const [lineHeight, setLineHeight] = useState<string | null>('');
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [linkAttrs, setLinkAttrs] = useState({ url: '', newWindow: true });
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const { selection } = editor.state;
    const selectionState = createSelectionState(selection);
    setSelectionTypeName(selectionState?.selectedNode?.node.type.name ?? '');
    if (editor) {
      setFontFamilyName(getFontName(editor) ?? 'Source sans pro');
      setFontSizeValue(getFontSize(editor) ?? fontSizeValue);
      setTextAlign(getTextAlign(editor));
      setLineHeight(getLineHeight(editor));
      setLinkAttrs(getCurrentLink(editor));
    }
  }, [editor.state.selection]);

  const getTextIndent = (typeName: string) => {
    let value = '0';
    const attr = getNodeAttributes(editor.state, typeName);
    const res = splitValueAndUnit(attr['textIndent'] as string);
    if (res?.value) value = res.value;
    return value;
  };

  const setTextIndent = (value: string | null) => {
    editor.commands.setTextIndent(`${value}em`);
  };

  const getIndentValue = (indentType: string, typeName: string): string => {
    if (indentType === 'textIndent') {
      return getTextIndent(typeName);
    }
    return getLeftPadding(typeName);
  };

  const setIndentValue = (indentType: string, indentValue: string): void => {
    if (indentType === 'textIndent') {
      setTextIndent(indentValue);
    } else {
      const { selection } = editor.state;
      const isTableSelected = findNodeTypeFromSelection('table',selection).node;
      if(isTableSelected && indentValue === '0')
      {
        editor.commands.setLeftPadding('5px');
        return;
      }
      setLeftPadding(indentValue);
    }
  };

  const setIndentSpacing = (indentType: string, indentOption: string) => {
    const currentIndentValue = getIndentValue(indentType, selectionTypeName);
    let updatedIndentValue: number;
    switch (indentOption) {
      case 'increase': {
        updatedIndentValue = Number(currentIndentValue) + CONSTANTS.LOREE_TEXT_INDENT_INDEX;
        setIndentValue(indentType, updatedIndentValue.toString());
        break;
      }
      case 'decrease':
        if (Number(currentIndentValue) < CONSTANTS.LOREE_TEXT_INDENT_INDEX) {
          setIndentValue(indentType, '0');
        } else {
          updatedIndentValue = Number(currentIndentValue) - CONSTANTS.LOREE_TEXT_INDENT_INDEX;
          setIndentValue(indentType, updatedIndentValue.toString());
        }
        break;
    }
  };

  const setLeftPadding = (value: string | null) => {
    editor.commands.setLeftPadding(`${value}em`);
  };

  const getLeftPadding = (typeName: string) => {
    let value = '0';
    const attr = getNodeAttributes(editor.state, typeName);
    const res = splitValueAndUnit(attr['paddingLeft'] as string);
    if(attr['paddingLeft'] === '5px') return value;
    if (res?.value) {
      value = res.value;
    }
    return value;
  };

  const clearContentFormatInsideEditor = () => {
    editor.commands.unsetUnderline();
    const { selection } = editor.state;
    const selectionState = createSelectionState(selection);
    console.log('Selection state node', selectionState.selectedNode?.node.type.name);

    const nodeTypeName = selectionState.selectedNode?.node.type.name;
    editor
      .chain()
      .focus()
      .unsetBold()
      .unsetItalic()
      .unsetStrike()
      .unsetAllMarks()
      .unsetTextAlign()
      .setTextIndent('0px')
      .setLeftPadding('0px')
      .updateAttributes(selection.$from.parent.type.name, { style: '', lineHeight: '' })
      .run();

    if (nodeTypeName === 'paragraph' && editorConfig) {
      let paragraphDefaultFontFamily: string = '';
      const paragraphDefaultFontSize: FontSizeData = { size: 16, unit: 'px' };
      const adminDashboardStyles = setDefaultFontStyle(editorConfig?.customHeaderStyleList, 0);
      if (adminDashboardStyles) {
        paragraphDefaultFontFamily = adminDashboardStyles.font;
        const customFontSize = parseFontSize(adminDashboardStyles.size);
        paragraphDefaultFontSize.size = customFontSize?.size as number;
        paragraphDefaultFontSize.unit = customFontSize?.unit ?? 'px';
        editor
          .chain()
          .setFontFamily(paragraphDefaultFontFamily)
          .setFontSize(paragraphDefaultFontSize)
          .run();
      }
    } else if (nodeTypeName === 'heading' && editorConfig) {
      const headingLevel = getHeadingLevel(editor);
      const adminDashboardStyles = setDefaultFontStyle(
        editorConfig?.customHeaderStyleList,
        headingLevel,
      );
      const customFontSize = parseFontSize(adminDashboardStyles.size);
      if (customFontSize) {
        editor
          .chain()
          .setFontFamily(adminDashboardStyles.font)
          .setFontSize({ size: customFontSize.size, unit: customFontSize?.unit })
          .run();
      }
    }

    if ((isBulletList(editor) || isOrderedList(editor)) && isWholeContentSelected(editor).status) {
      setTextColor('', editor);
      applyStylesToList({ fontFamily: 'SourceSansPro-Regular' }, 'fontFamily', editor);
      applyStylesToList({ fontSize: formatFontSize({ size: 16, unit: 'px' }) }, 'fontSize', editor);
    }
  };

  const handleApplyLink = (url: string, isNewTab: boolean) => {
    setShowLinkModal(false);
    applyLink(editor, url, isNewTab);
  };
  return (
    <FormatSection>
      <HistoryFormatButtons editor={editor} />
      <HeadingFormatButton editorConfig={editorConfig} editor={editor} />
      <TextFormatButton editor={editor} />
      <FontSectionContent
        editor={editor}
        editorConfig={editorConfig}
        fontFamilyName={fontFamilyName}
        fontSizeValue={fontSizeValue}
      />
      <TextColorButton
        onChange={(color) => setTextColor(color, editor)}
        currentColor={getTextColor(editor)}
        globalColorList={[]}
        sharedColors={[]}
        colorPickerFeatures={CONSTANTS.LOREE_COLORPICKER_FEATURES}
        colorContrastRatio={() => {}}
        colorPickerType='text'
      ></TextColorButton>
      <BackgroundColorButton
        onChange={(color) => setBackgroundColor(color, editor)}
        currentColor={getBackgroundColor(editor)}
        globalColorList={[]}
        sharedColors={[]}
        colorPickerFeatures={CONSTANTS.LOREE_COLORPICKER_FEATURES}
        colorContrastRatio={() => {}}
        colorPickerType='Background'
      />
      <BorderColorButton
        onChange={(color) => setBorderColor(editor, color)}
        currentColor={getBorderColor(editor)}
        globalColorList={[]}
        sharedColors={[]}
        colorPickerFeatures={CONSTANTS.LOREE_COLORPICKER_FEATURES}
        colorContrastRatio={() => {}}
        colorPickerType='Background'
        currentSize={getBorderSize(editor)}
        currentStyle={getBorderStyle(editor)}
        onChangeSize={(size) => setBorderSize(editor, size)}
        onChangeStyle={(style) => setBorderStyle(editor, style)}
      />
      <ListElementOption editor={editor} />
      <AlignmentButton
        activeLeft={textAlign === 'left'}
        activeCenter={textAlign === 'center'}
        activeRight={textAlign === 'right'}
        activeJustify={textAlign === 'justify'}
        onLeft={() => editor.commands.setTextAlign('left')}
        onCenter={() => editor.commands.setTextAlign('center')}
        onRight={() => editor.commands.setTextAlign('right')}
        onJustify={() => editor.commands.setTextAlign('justify')}
      />
      <TextIndentButton
        {...{
          setIndentSpacing,
          getLeftPadding,
          getTextIndent,
          selectionTypeName,
        }}
      />
      <EditorFormatButton
        className={
          editor.isActive('blockquote')
            ? `${lintStyle.formatButton} is-active`
            : `${lintStyle.textFormatButton}`
        }
        onClick={() => editor.commands.toggleBlockquoteContent()}
      >
        <Tooltip info='Blockquote'>
          <BlockquoteButton className='textFormatButton' />
        </Tooltip>
      </EditorFormatButton>
      <WordOptionsButton editor={editor} />
      <LineHeightButton {...{ lineHeight, onChangeLineHeight }} />
      <MediaContent editor={editor} setIsUploading={(value: boolean) => setIsUploading(value)} />
      <TableFormatContent editor={editor} editorConfig={editorConfig} />
      <EditorFormatButton
        className={
          isLinkText(editor)
            ? `${lintStyle.formatButton} is-active`
            : `${lintStyle.textFormatButton}`
        }
        onClick={() => setShowLinkModal(true)}
      >
        <Tooltip info='Add Link'>
          <LinkButton className='textFormatButton' />
        </Tooltip>
      </EditorFormatButton>
      <WebaddressModal
        url={linkAttrs.url}
        newTab={linkAttrs.newWindow}
        show={showLinkModal}
        handleCancel={() => setShowLinkModal(false)}
        handleApply={handleApplyLink}
      ></WebaddressModal>
      <Tooltip info='Clear format'>
        <ClearEditorButton
          className={lintStyle.formatButton}
          onClick={() => clearContentFormatInsideEditor()}
        />
      </Tooltip>
      {isUploading && (
        <small className='ms-1 text-primary'>
          <i className='fas fa-sync fa-spin' /> Uploading image
        </small>
      )}
    </FormatSection>
  );
}
