import { Editor } from '@tiptap/core';
import { EditorState } from '@tiptap/pm/state';
import { CONSTANTS } from './editorUtilityFunctions/constants';
import { LoreeInteractiveEditorDashboardContentType } from './editorUtilityFunctions/lintEditorType';
import { setDefaultFontStyle } from './editorExtensions/utilityFunctions';

const allowedSpaceElementAtTheEndPoint = ['TABLE'];

export const migrateContent = (html?: string): string => {
  if (!html) return '';
  let wrapper = document.createElement('div');
  wrapper.setAttribute('id', 'tempConvertContent');
  wrapper.innerHTML = html;
  migrateForTextIndent(wrapper);
  migrateForTextAlign(wrapper);
  migrateForFontFamily(wrapper);
  imageMigration(wrapper);
  migrateForListElements(wrapper, 'ul');
  migrateForListElements(wrapper, 'ol');
  migrateParagraphFontStyles(wrapper);
  return wrapper.innerHTML;
};

const migrateForListElements = (wrapper: HTMLElement, listParent: string): void => {
  const unOrderedListElements = wrapper.querySelectorAll(`${listParent}:not([type])`);
  const indentLevelTypes = ['a', 'i', '1'];
  unOrderedListElements.forEach((unOrderedListElement) => {
    if (unOrderedListElement.children.length === 0) return;
    let currentIndentLevel = 0;
    if ((unOrderedListElement as HTMLElement).style.listStyleType === 'none') return;
    const listItems = unOrderedListElement.querySelectorAll('li');
    let newOl = document.createElement('ol');
    let sameTag = false;
    const result = document.createElement('ul');
    let tempResult = result as HTMLElement;

    for (let listItemCount = 0; listItemCount < listItems.length; listItemCount++) {
      const listItem = listItems[listItemCount];
      if (!listItem.hasChildNodes()) {
        listItem.innerHTML = '<p>' + listItem.innerHTML + '</p>';
      } else if (listItem.firstElementChild?.tagName !== 'OL') {
        listItem.innerHTML = '<p>' + listItem.innerHTML + '</p>';
      }

      tempResult = listItem;
      const indentLevel =
        Array.prototype.slice
          .call(listItem.classList)
          .filter((c) => c.startsWith('ql-indent-'))
          .map((c) => parseInt(c.replace('ql-indent-', '')))[0] || 0;

      if (listItemCount === 0 && indentLevel !== 0) {
        result.setAttribute('style', 'list-style-type:none');
      }

      listItem.removeAttribute('class');

      if (currentIndentLevel < indentLevel) {
        newOl = document.createElement('ol');
        newOl.type = indentLevelTypes[(indentLevel % 3) - 1];
        sameTag = true;
        newOl.innerHTML = listItem.outerHTML;
        tempResult = newOl;
        if (indentLevel === 1) {
          result.appendChild(tempResult);
        } else {
          const olTag = result.getElementsByTagName('ol');
          if (olTag.length === 0) {
            result.appendChild(tempResult);
          } else {
            olTag[olTag.length - 1].append(tempResult);
          }
        }
        currentIndentLevel = indentLevel;
      } else if (currentIndentLevel > indentLevel) {
        sameTag = false;
        result.appendChild(tempResult);
        currentIndentLevel = indentLevel;
      } else if (sameTag) {
        newOl.appendChild(tempResult);
      } else {
        sameTag = false;
        result.appendChild(tempResult);
      }
    }  
    unOrderedListElement.outerHTML = result.outerHTML;
  });
};

const migrateForTextIndent = (wrapper: HTMLDivElement): void => {
  const indentTags = wrapper.querySelectorAll('[class*=ql-indent]');
  Array.from(indentTags).forEach((element) => {
    if (['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P'].includes(element.tagName)) {
      setIndentValue(element as HTMLElement);
    }
  });
};

const migrateForTextAlign = (wrapper: HTMLDivElement): void => {
  const alignmentElements = wrapper.querySelectorAll('[class*=ql-align]');
  Array.from(alignmentElements).forEach((element) => {
    setTextAlignment(element as HTMLElement);
  });
};

export const setIndentValue = (element: HTMLElement): void => {
  for (let indentSpace of CONSTANTS.LOREE_INDENT_SPACE) {
    if (element.classList.contains(indentSpace.className)) {
      element.style.paddingLeft = indentSpace.value;
      element.classList.remove(indentSpace.className);
      break;
    }
  }
};

export const setTextAlignment = (element: HTMLElement): void => {
  for (let alignmentPos of CONSTANTS.LOREE_ALIGNMENT_VALUES) {
    if (element.classList.contains(alignmentPos.className)) {
      element.style.textAlign = alignmentPos.value;
      element.classList.remove(alignmentPos.className);
      break;
    }
  }
};

export const migrateForFontFamily = (element: HTMLElement) => {
  let fontElements = element.querySelectorAll('[class^=ql-font-]');
  if (fontElements.length > 0) {
    fontElements.forEach((fontElement) => {
      CONSTANTS.LOREE_FONTFAMILY_VALUES.filter((fontFamily) => {
        return fontElement.classList.contains(fontFamily.className);
      }).forEach((filteredFontFamily) => {
        if (fontElement.hasAttribute('style')) {
          (fontElement as HTMLElement).style.fontFamily = filteredFontFamily.value;
        } else {
          fontElement.setAttribute('style', `font-family: ${filteredFontFamily.value}`);
        }
        fontElement.classList.remove(filteredFontFamily.className);
      });
    });
  }
};

export const imageMigration = (element: HTMLElement): void => {
  let imageContents = element.querySelectorAll('img');
  if (imageContents.length > 0) {
    imageContents.forEach((imageContent) => {
      if (imageContent.parentElement?.tagName === 'P') {
        const parentContent = imageContent.parentElement;
        const parentClasses = parentContent.getAttribute('class');
        const parentStyles = parentContent.getAttribute('style');

        const imageBeforeElement = document.createElement('p');
        imageBeforeElement.setAttribute('style', parentStyles as string);
        imageBeforeElement.setAttribute('class', parentClasses as string);

        let previousSibling = imageContent.previousSibling;
        let previousSiblingString = '';

        while (previousSibling) {
          previousSiblingString =
            previousSibling.nodeType === 3
              ? (previousSibling.textContent as string)
              : (previousSibling as HTMLElement).outerHTML;
          imageBeforeElement.innerHTML += previousSiblingString;
          previousSibling = previousSibling.previousSibling;
        }

        const imageAfterElement = document.createElement('p');
        imageAfterElement.setAttribute('style', parentStyles as string);
        imageAfterElement.setAttribute('class', parentClasses as string);

        let nextSibling = imageContent.nextSibling;
        let nextSiblingString = '';

        while (nextSibling) {
          nextSiblingString =
            nextSibling.nodeType === 3
              ? (nextSibling.textContent as string)
              : (nextSibling as HTMLElement).outerHTML;
          imageAfterElement.innerHTML += nextSiblingString;

          nextSibling = nextSibling.nextSibling;
        }

        const wholeMigratedContentChange = document.createElement('div');

        wholeMigratedContentChange.append(imageBeforeElement);
        wholeMigratedContentChange.append(imageContent);
        wholeMigratedContentChange.append(imageAfterElement);

        parentContent.replaceWith(wholeMigratedContentChange);
      }
    });
  }
};

export const migrateParagraphFontStyles = (element: HTMLElement) => {
  const paragraphElements = element.getElementsByTagName('p');

  Array.from(paragraphElements).forEach((paragraphElement) => {
    if (
      paragraphElement.hasAttribute('style') &&
      paragraphElement.getAttribute('style')?.indexOf('font-family:') !== -1 &&
      paragraphElement.getAttribute('style')?.indexOf('font-size:') !== -1
    ) {
      return;
    } else if (
      paragraphElement.hasAttribute('style') &&
      paragraphElement.getAttribute('style')?.indexOf('font-family:') !== -1
    ) {
      paragraphElement.style.fontSize = '16px';
    } else if (
      paragraphElement.hasAttribute('style') &&
      paragraphElement.getAttribute('style')?.indexOf('font-size:') !== -1
    ) {
    } else if (!paragraphElement?.hasAttribute('style')) {
      paragraphElement.setAttribute('style', `font-family: SourceSansPro-Regular; font-size: 16px`);
    }
  });
};

export const updateMigration = (
  editorTemp: Editor,
  editorConfig: LoreeInteractiveEditorDashboardContentType,
) => {
  if (editorTemp.getHTML() === '<table></table>') {
    editorTemp.chain().setContent(CONSTANTS.LOREE_EDITOR_EMPTY_CONTENT).focus('start').run();
  }
  var end = editorTemp.state.doc.content.size - 1;
  const endPointContent = editorTemp.view.domAtPos(end);

  const editorWrapper = document.createElement('div');
  editorWrapper.innerHTML = editorTemp.getHTML();

  const paragraphElements = editorWrapper.querySelectorAll('p');

  let adminDashboardStyles:
    | {
        size: string;
        font: string;
      }
    | undefined;

  if (editorConfig?.customHeaderStyleList.length) {
    adminDashboardStyles = setDefaultFontStyle(editorConfig?.customHeaderStyleList, 0);
  }

  let fontFamilyStyle = adminDashboardStyles?.font;
  let fontSizeStyle = adminDashboardStyles?.size;

  const applyStylesToParagraphs = () => {
    paragraphElements.forEach((paragraph) => {
      if (fontFamilyStyle && paragraph.style.fontFamily !== fontFamilyStyle) {
        paragraph.style.fontFamily = fontFamilyStyle;
      }
      if (fontSizeStyle && paragraph.style.fontSize !== fontSizeStyle) {
        paragraph.style.fontSize = fontSizeStyle;
      }
    });
    editorTemp.chain().setContent(editorWrapper.innerHTML).run();
  };

  if (
    adminDashboardStyles &&
    (editorTemp.getHTML() === '<p></p>' ||
      paragraphElements[0]?.innerText.trim() ||
      paragraphElements[0]?.classList.contains('lint-editor-is-empty-class'))
  ) {
    const paragraphElement = paragraphElements[0];
    if (
      paragraphElement.hasAttribute('style') &&
      paragraphElement.getAttribute('style')?.indexOf('font-family:') !== -1
    ) {
      return;
    } else if (
      paragraphElement.firstElementChild?.hasAttribute('style') &&
      paragraphElement.firstElementChild?.getAttribute('style')?.indexOf('font-family:') !== -1
    ) {
      return;
    }

    let fontFamilyStyle = (paragraphElement.firstElementChild as HTMLElement)?.style?.fontFamily;
    let fontSizeStyle = (paragraphElement.firstElementChild as HTMLElement)?.style?.fontSize;

    if (!fontFamilyStyle) {
      fontFamilyStyle = adminDashboardStyles?.font;
    }

    if (!fontSizeStyle) {
      fontSizeStyle = adminDashboardStyles?.size;
    }

    if (fontFamilyStyle && fontSizeStyle) {
      applyStylesToParagraphs();
    }
  }

  if (allowedSpaceElementAtTheEndPoint.includes(endPointContent.node.nodeName)) {
    editorTemp
      .chain()
      .insertContentAt(end + 1, CONSTANTS.LOREE_EDITOR_EMPTY_CONTENT)
      .run();
  }
};

export const createMigration = (
  editor: Editor,
  editorConfig: LoreeInteractiveEditorDashboardContentType,
) => {
  if (editor.getHTML() === '<p></p>') {
    let adminDashboardStyles;
    if (editorConfig?.customHeaderStyleList.length) {
      const editorWrapper = document.createElement('div');
      editorWrapper.innerHTML = editor.getHTML();
      const paragraphElements = editorWrapper.getElementsByTagName('p');
      const paragraphElement = paragraphElements[0];

      adminDashboardStyles = setDefaultFontStyle(editorConfig?.customHeaderStyleList, 0);

      let fontFamilyStyle = (paragraphElement.firstElementChild as HTMLElement)?.style?.fontFamily;
      let fontSizeStyle = (paragraphElement.firstElementChild as HTMLElement)?.style?.fontSize;

      if (!fontFamilyStyle) {
        fontFamilyStyle = adminDashboardStyles?.font;
      }

      if (!fontSizeStyle) {
        fontSizeStyle = adminDashboardStyles?.size;
      }

      if (fontFamilyStyle && fontSizeStyle) {
        editor
          .chain()
          .focus()
          .setCustomParagraph({
            style: `font-family: ${fontFamilyStyle}; font-size: ${fontSizeStyle}`,
            attrs: null,
          })
          .run();
      }
    }
  }
  /** For resetting the state of history */
  const newEditorState = EditorState.create({
    doc: editor.state.doc,
    plugins: editor.state.plugins,
    schema: editor.state.schema,
  });
  editor.view.updateState(newEditorState);
};
