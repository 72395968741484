/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { ToastManager } from '@crystaldelta/loree-ui-components';
import { ReactComponent as AddIcon } from '../../../assets/icons/Add.svg';
import MediaModal from '../../editor/mediaManagement/mediaModal';
import { FormInterface } from '../../../redux/interface';
import { setParameter } from '../../../redux/form/form.actions';
import ReactHtmlParser from 'react-html-parser';
import AddArea from './addArea';
import { fetchImages, uploadToS3 } from '../../../middleware/api';
import { setImageCollection } from '../../../redux/mediaManagement/images.action';
import { getValueFromSession } from '../../../middleware/utils';
import { LoreeInteractiveEditorDashboardContentType } from '../../editor/editorUtilityFunctions/lintEditorType';

interface FormComponentInterface {
  type: string;
  index: number;
  panel: Array<string>;
  editorConfig: LoreeInteractiveEditorDashboardContentType;
}

const mapState = ({ form, imageStorage }: any) => ({
  dndLayout: form.dndLayout,
  dndParameters: form.dndParameters,
  errors: form.errors,
  imageCollection: imageStorage.imageCollection,
});

const mapDispatch = (dispatch: Function) => ({
  HandleParameters: (form: FormInterface) => dispatch(setParameter(form)),
  SetImageCollection: (imageStorage: {}) => dispatch(setImageCollection(imageStorage)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & FormComponentInterface;

const FormComponent = (props: Props) => {
  const {
    type,
    index,
    HandleParameters,
    dndParameters,
    errors,
    SetImageCollection,
    panel,
    editorConfig,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [altText, setAltText] = useState('');
  const [imageType, setImageType] = useState('');
  const [imageFileDetails, setImageFileDetails] = useState({});
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [imageHovered, setImageHovered] = useState(false);

  useEffect(() => {
    const renderImageCollections = async () => {
      if (props.imageCollection.length === 0) {
        await updateImageCollection();
      }
    };
    renderImageCollections();
  });

  const updateImageCollection = async () => {
    try {
      setFetching(true);
      const orgId = getValueFromSession('org_id');
      const getImages = await fetchImages(orgId);
      SetImageCollection({ imageCollection: getImages });
      setFetching(false);
    } catch (error) {
      console.log('error occured while fetching images', error);
      setFetching(false);
      setError(true);
    }
  };
  const uploadLocalImage = (updatedFileName: string) => {
    setUploading(true);
    saveImageToS3(updatedFileName);
  };

  const saveImageToS3 = async (updatedFileName: string) => {
    try {
      const updatedFileDetatils = new File([imageFileDetails as Blob], `${updatedFileName}`, {
        type: (imageFileDetails as Blob)?.type,
      });
      const data = new FormData();
      data.append('image', updatedFileDetatils);
      const updatedFile = await uploadToS3(data);
      insertImageToSection(updatedFile.url);
      setUploading(false);
      setImageFileDetails({} as Blob);
      await updateImageCollection();
    } catch (error) {
      console.error('error in uploadingImages', error);
      setShowToast(true);
      setToastMessage('An error occured while uploading Images');
      setUploading(false);
    }
  };

  const handleImageSection = (value: string, altValue: string) => {
    let content = [...dndParameters];
    let errorObject = { ...errors };
    delete errorObject[`${panel[0]}`];
    delete errorObject[`${panel[1]}`];
    delete errorObject[`alt_${panel[0]}`];
    if (value === '') {
      content[index] = { ...content[index], background: value, dropZone: [], alt: altValue };
    } else {
      content[index] = { ...content[index], background: value, alt: altValue };
    }
    HandleParameters({
      dndParameters: content,
      errors: errorObject,
    });
  };

  const insertImageToSection = (url: string) => {
    const selectedImage = `<img src='${url}' alt='${altText}' role='${
      altText === '' ? 'presentation' : ''
    }'>`;
    handleImageSection(selectedImage, altText);
  };

  const getBackgroundImage = () => {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = dndParameters[index].background;
    return wrapper?.getElementsByTagName('img')[0];
  };

  const setAltValue = (altValue: string, eventType: string) => {
    let decorativeText = dndParameters[index].alt;
    decorativeText = decorativeText ? decorativeText : getAltValue();
    decorativeText = decorativeText === '' ? ' ' : decorativeText;
    let alt = eventType === 'change' ? altValue : decorativeText;
    if (eventType === 'click' && altValue === ' ') {
      alt = decorativeText;
    }
    const imageTag = getBackgroundImage();
    if (!imageTag) return;
    imageTag.alt = eventType === 'click' && altValue === ' ' ? alt : altValue;
    imageTag.role = altValue === '' ? 'presentation' : '';
    handleImageSection(`${imageTag.outerHTML}`, alt);
  };

  const getAltValue = () => {
    const imageTag = getBackgroundImage();
    return imageTag ? imageTag.alt : '';
  };
  return (
    <div className='py-1'>
      <Form.Group className='section-content'>
        <Form.Label className='title'>Image *</Form.Label>
      </Form.Group>
      {!dndParameters[index]?.background && (
        <div
          role='button'
          tabIndex={0}
          data-testid='addMediaSection'
          className='pt-3 text-center addMediaSection'
          onClick={() => setShowModal(true)}
          onKeyDown={() => setShowModal(true)}
        >
          <AddIcon />
          <p className='pt-1'>{uploading ? 'Uploading...' : 'Click here to add an image'}</p>
        </div>
      )}
      {dndParameters[index]?.background && (
        <Row>
          <Col
            className='interactive-image-container'
            data-testid='interactive-image-container'
            md={5}
            onMouseEnter={() => setImageHovered(true)}
            onMouseLeave={() => setImageHovered(false)}
          >
            <div id={`${index}_backgroundImage`} className='image-wrapper-container'>
              <div className='image-wrapper' data-testid='image-wrapper'>
                {ReactHtmlParser(dndParameters[index].background)}
                <div
                  className={`overlay ${imageHovered ? 'visible' : ''}`}
                  data-testid='image-wrapper-overlay'
                />
              </div>
              {imageHovered && (
                <div className='btn-container'>
                  <button
                    className='remove-img-btn'
                    data-testid='remove-img-btn'
                    onClick={() => {
                      handleImageSection('', '');
                    }}
                  >
                    Remove
                  </button>
                  <button
                    className='replace-img-btn'
                    data-testid='replace-img-btn'
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Replace
                  </button>
                </div>
              )}
            </div>
            <AddArea type={type} index={index} panel={panel} editorConfig={editorConfig} />
            {errors[`${panel[1]}`] && (
              <Form.Text className='text-danger'>{errors[`${panel[1]}`]}</Form.Text>
            )}
          </Col>
          <Col md={7}>
            <Form.Label className='mx-1 mb-1'>Alternative text *</Form.Label>
            <textarea
              id='lint-image-alt-input'
              className='mx-1 w-100 right-container'
              placeholder='  Enter alt text'
              value={
                dndParameters[index].alt
                  ? dndParameters[index].alt === ' '
                    ? dndParameters[index].alt.trim()
                    : dndParameters[index].alt
                  : getAltValue().trim()
              }
              maxLength={150}
              disabled={getAltValue() === ''}
              onChange={(event) =>
                setAltValue(event.target.value === '' ? ' ' : event.target.value, 'change')
              }
            />
            <Form.Group>
              <Form.Check
                checked={getAltValue() === ''}
                className='mx-1 my-2'
                label='Decorative image'
                id={`decorativeImageCheckbox-${index}`}
                onChange={(event) => {
                  setAltValue(event.target.checked ? '' : ' ', 'click');
                }}
              />
            </Form.Group>
            {errors[`alt_${panel[0]}`] && (
              <Form.Text className='text-danger'>{errors[`alt_${panel[0]}`]}</Form.Text>
            )}
          </Col>
        </Row>
      )}
      {errors[`${panel[0]}`] && (
        <Form.Text className='text-danger'>{errors[`${panel[0]}`]}</Form.Text>
      )}
      <MediaModal
        show={showModal}
        setDecorativeText={(text: string) => setAltText(text)}
        decorativeText=''
        isErrorInFetch={error}
        isFetching={fetching}
        imageType={imageType}
        mediaType='Media'
        setShow={() => setShowModal(false)}
        handleAddElement={(url: string, type: string) => {
          insertImageToSection(url);
        }}
        handleLocalImageUpload={{
          setLocalImageDetails: (type: string, file: any) => {
            setImageFileDetails(file);
            setImageType(type);
          },
          uploadImage: (fileName: string) => uploadLocalImage(fileName),
        }}
        componentType={type}
      />
      {showToast && (
        <ToastManager
          toastType='error'
          toastMessage={toastMessage}
          closeButton
          closeToast={() => setShowToast(false)}
        />
      )}
    </div>
  );
};

export default connector(FormComponent);
